.error-message {
    height: 90vh;
    display: grid;
    place-content: center;
    color: white;
    text-transform: uppercase;
    font-size: 2.5em;
    font-weight: bold;
}
.container {
    &>.tournament-list--page {
        max-width: 1300px;
        margin: 0 auto;
        padding: 40px 0px;
        &>.tournament {
            &>h1 {
                padding-left: 20px;
                color: var(--accent-primary);
                font: 10em 'Hyperwave';
            }
            &>.stage {
                padding-bottom: 50px;
                &>h2 {
                    font-size: 3em;   
                    font-weight: bold;
                    padding-left: 10px;
                }
                &>.scroller {
                    width: 100%;
                    overflow: scroll;
                    padding: 30px 0px;
                    &>.scroll-content--holder {
                        display: grid;
                        grid-auto-columns: max-content;
                        grid-auto-flow: column;
                        column-gap: 20px;
                        &>.day {
                            padding: 20px;
                            height: max-content;
                            &>.match {
                                &>h3 {
                                    margin: 0;
                                    font-size: 1.2em;
                                }
                                &>.game-select {
                                    margin: 0 0 0 20px;
                                    font-size: 0.8em;
                                }
                            }


                            &>h3 {
                                font-weight: bold;
                                font-size: 1.8em;
                                padding-bottom: 20px;
                            }
                            &>div.match {
                                padding: 5px 0px;
                                &>a {
                                    font-size: 1.2em;
                                    text-decoration: none;
                                    color: #bbb;
                                    transition: color 150ms;
                                    &:hover {
                                        color: var(--accent-primary);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}