.champion-pick--wrapper {
    position: relative;
    margin: 5px 0px;
    background-color: var(--champion-pick-bg);
    transition: height 350ms, border-width 350ms;
    overflow: hidden;

    &:hover>.pick {
        filter: contrast(0.8);
        
        &>h3 {
            color: var(--accent-primary);
        }
    }

    &>.pick {
        height: 100%;
        transition: border-width 350ms;
        background-size: cover;
        transition: filter 350ms;
        &>h3 {
            position: absolute;
            bottom: 0px;
            font: 4em 'Hyperwave';
            color: white;
            text-shadow: 2px 2px 1px var(--elevation-shadow);
            transition: color 350ms;
        }
        &>span {
            display: none;
            font-family: 'Rubik';
            position: absolute;
            bottom: 2.2em;
            text-shadow: 1px 1px 2px  var(--elevation-shadow);
            font-size: 1.8em;
            padding: none;
            animation: fade 750ms alternate infinite ease-in-out;
        }
    }
    &.currently-picking>.pick>span {
        display: inline;
    }

    &.left {
        // border-right: 2px solid var(--accent-primary);
        border-left: 2px solid var(--accent-primary);

        transform: scaleX(-1);

        & .pick>h3 {
            transform: scaleX(-1);

            left: 20px;
        }
        & .pick>span {
            transform: scaleX(-1);

            left: 15px;
        }
    }

    &.right {
        border-left: 2px solid var(--accent-secondary);

        & .pick>h3 {
            left: 20px;
        }
        & .pick>span {
            left: 15px;
        }
    }

    &.currently-picking {
        border-width: 15px;
    }
}
