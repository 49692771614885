.container {
    box-sizing: border-box;
    overflow: hidden;
    transition: max-height 150ms ease-in-out;
    max-height: 0vh;

    &.open {
        max-height: 95vh;
    }

    & #counters {
        height: calc(100vh - 400px);
        border-radius: 0px 2px 2px;
    }

    &>.content {
        &>nav.options-tabs {
            background-color: inherit;
            display: grid;
            column-gap: 5px;
            // grid-template-columns: min-content min-content auto min-content;
            grid-template-columns: min-content auto min-content;
            overflow: hidden;

            &>button {
                width: min-content;
                font-size: 1em;
                color: grey;
                padding: 8px 15px;
                border-radius: 4px 4px 0px 0px;
                box-shadow: none;
                font-family: 'Rubik';

                &.selected {
                    color: white;
                    background-color: var(--bg-primary);
                }
                &.close {
                    background-color: transparent;
                    // border-radius: 50%;
                    cursor: pointer;
                }
            }
        }

        &>.page {
            box-sizing: border-box;
            background-color: var(--bg-primary);
            border-radius: 0px 4px 4px 4px;

            &.rounded {
                border-radius: 4px;
            }

            & .small {
                margin: 0 auto;
                padding: 20px 30px;
                max-width: 450px;
            }

            &>div {
                margin: 0 auto;
            }
        }
    }
}
