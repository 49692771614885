input[type=text].component
    box-sizing: border-box
    font-family: 'Rubik'
    width: 100%
    font-size: 1.4em
    padding: 6px 10px
    margin-bottom: 5px
    border-radius: 2px
    box-shadow: 0.04em 0.04em 3px 1px var(--elevation-shadow)
    &:disabled
        cursor: not-allowed
