nav.container {
    box-sizing: border-box;
    position: sticky;
    z-index: 500;
    top: 0px;
    height: 62px;
    width: 100%;
    padding: 0px 10px;
    display: grid;
    grid-template-columns: 70px 1fr 50px 565px 50px 1fr 70px;
    column-gap: 40px;
    background-color: var(--nav-bg);
    color: white;
    grid-template-areas: "logo blue-team blue-counter middle red-counter red-team toggle";

    @media screen and (max-width: 1500px) {
        grid-template-columns: 70px 1fr 50px 400px 50px 1fr 70px;
    }

    @media screen and (max-width: 1300px) {
        grid-template-columns: 70px 1fr 50px 300px 50px 1fr 70px;
    }

    @media screen and (max-width: 800px) {
        grid-template-rows: 45px;
        grid-template-columns: 70px 1fr 50px 1fr 70px;
        height: 60px;
        column-gap: 10px;
        grid-template-areas:
            "logo middle middle middle toggle";
    }

    & a.name {
        background-color: transparent;
        transition: background-color 250ms;

        &>svg {
            box-sizing: border-box;
            height: 60px;
            width: 70px;
            padding: 12px;

            @media screen and (max-width: 800px) {
                height: 50px;
            }
        }


        &:hover {
            background-color: var(--bg-primary-light-2);
        }
    }

    &>h1 {
        font: 3.6em 'Hyperwave';
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        grid-area: middle;
    }

    &>h2 {
        font: 2em 'Arvo', serif;
        padding-top: 10px;
        color: grey;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        transition: color 350ms;

        @media screen and (max-width: 800px) {
            font-size: 1.3em;
        }

        &.blue {
            grid-area: blue-team;
            text-align: right;
        }

        &.red {
            grid-area: red-team;
            text-align: left;
        }

        &.active {
            color: white;
        }
    }

    &>p {
        font: 2.8em 'Arvo', serif;
        display: grid;
        place-content: center;

        @media screen and (max-width: 800px) {
            font-size: 2.3em;
        }

        &.blue {
            filter: saturate(0.7) brightness(2);
            color: var(--accent-primary);
            grid-area: blue-counter;

            @media screen and (max-width: 800px) {
                grid-area: counter;
            }
        }

        &.red {
            filter: saturate(0.25) brightness(2);
            color: var(--accent-secondary);
            grid-area: red-counter;

            @media screen and (max-width: 800px) {
                grid-area: counter;
            }
        }
    }

    &.with-bar {
        @media screen and (max-width: 800px) {
            height: 92px;
            grid-template-rows: 45px 40px;
            grid-template-areas:
                "logo middle middle middle toggle"
                "blue-team blue-team counter red-team red-team";
        }

        &::after,
        &::before {
            content: '';
            height: 2px;
            width: 100%;
            position: absolute;
            left: 50vw;
            transform: translateX(-50%);
            bottom: 0px;
        }

        &::after {
            background-color: var(--picking-side);
            transition: width 250ms linear;
            width: var(--navbar-length);
        }

        &::before {
            background-color: var(--bg-primary-light-2);
        }
    }
}
