.champion-select-display--wrapper {
    margin: 0 auto;
    display: grid;
    min-height: 0;
    max-height: calc(100vh - 90px);
    max-width: 800px;
    height: 100%;
    width: 100%;
    grid-template-rows: 1fr auto 150px;
    row-gap: 20px;
    grid-area: select;

    @media screen and (max-width: 800px) {
        box-sizing: border-box;
        grid-template-rows: 1fr auto 150px;
        height: 80vh;
        padding: 10px;
    }

    &>.select {
        min-height: 0;
    }
}
