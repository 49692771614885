.draft-settings--wrapper {
    &>.time-limits {
        display: grid;
        padding: 15px 0px 0px 0px;
        grid-template-columns: 1fr 80px;
        row-gap: 15px;
        column-gap: 10px;
        font-family: 'Rubik';
        font-size: 1.3em;

        &>h3 {
            font-size: 1.5em;
            grid-column: 1/3;
            border-bottom: 4px solid var(--bg-primary-light);
            padding-bottom: 10px;
        }

        .timer-toggle {
            margin-left: auto;
            padding-right: 5px;
        }

        &>label.seconds {
            padding-top: 8px;
            color: #aaa;
        }

        &>input[type=text] {
            text-align: center;
            font-size: 1em;

        }
    }
}
