.container {
    display: grid;
    height: calc(100vh - 120px);
    padding-bottom: 60px;
    place-content: center;
    @media screen and (max-width: 1000px) {
        height: min-content;
    }

    &>.selection--holder {
        display: grid;
        max-width: 1100px;
        padding: 0px 40px;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
        @media screen and (max-width: 1000px) {
            grid-template-rows: repeat(3, auto);
            grid-template-columns: auto;
            row-gap: 30px;
            column-gap: 0px;
        }
        &>h1 {
            font: 6em 'Rubik';
            padding: 20px;
            text-transform: uppercase;
            text-align: center;
            margin-top: -15vh;
            grid-column: 1/4;
            color: rgb(58, 58, 58);
            @media screen and (max-width: 1000px) {
                margin-top: 0;
                font-size: 3em;
                padding: 0;
                grid-column: 1/2;
            }
        }

        &>a {
            padding: 20px 30px 40px;
            text-decoration: none;
            border: 1px solid transparent;

            transition: box-shadow 350ms, background-color 350ms, border-color 250ms;

            &>.selection {
                text-decoration: none;
                transition: filter 350ms;
                display: grid;
                row-gap: 15px;
                &>h2 {
                    height: 60px;
                    padding-left: 10px;
                    font: 5em 'Hyperwave';
                }
                &>span {
                    color: grey;
                }
                &>p {
                    font-size: 1.3em;
                    line-height: 1.2em;
                    color: #bbb;
                }

                &.test>h2 {
                    color: var(--accent-secondary);
                }

                &.draft>h2 {
                    color: var(--accent-primary);
                }

                &.explore>h2 {
                    color: var(--accent-tertiary);
                    filter: brightness(1.5);
                }
            }

            &:hover {
                border-color: gold;

                &>.selection {
                    filter: brightness(1.3);
                }

                &.test {
                    border-color: var(--accent-secondary);
                }

                &.draft {
                    border-color: var(--accent-primary);
                }

                &.explore {
                    border-color: var(--accent-tertiary);
                }
            }

            &:active {
                background-color: #202020;
            }
        }
    }
}
