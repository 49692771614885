.container {
    display: grid;
    grid-template-columns: 80px 1fr 420px;
    grid-template-rows: auto 1fr;
    column-gap: 20px;
    padding: 20px 20px 80px;
    min-height: 100vh;

    &>h1.title {
        min-height: 0;
        margin: 0;
        grid-column: 2/4;
        font-size: 10em;
        padding: 0px 10px 20px;
        color: var(--accent-secondary);
        font-family: 'Hyperwave';
    }

    & .list {
        display: grid;
        row-gap: 15px;

        &>.error-message {
            display: grid;
            place-content: center;
        }

        &>.list-item {
            display: grid;
            grid-template-columns: 150px 150px 3fr 100px;
            column-gap: 20px;
            padding: 20px;
            border-radius: 4px;
            font-size: 1.2em;
            text-align: left;
            text-decoration: none;
            background-color: #151515;
            text-transform: none;

            &.header {
                background-color: gold;
                color: black;
                font-weight: bold;
                text-transform: uppercase;
            }

            &.selected {
                background-color: #252525;
            }
        }
    }
}
