// :root{
//     --bg-primary-dark: #fff;
//     --bg-primary-dark-1: #fff;
//     --bg-primary: #fff;
//     --bg-primary-light-1: #fff;
//     --bg-primary-light-2: #fff;
//     --bg-primary-light: #fff;
//     --text-primary: black;
//     --accent-primary: #FFD700;
//     --accent-secondary: #FF00FF;
//     --accent-tertiary: #5600b3;
//     --success: #7EFF00;
// }
:root {
    --navbar-length: 100%;
    
    --bg-primary-dark: #000000;
    --bg-primary-dark-1: #050505;
    --bg-primary: #101010;
    --bg-primary-light-1: #151515;
    --bg-primary-light-2: #1a1a1a;
    --bg-primary-light-3: #202020;
    --bg-primary-light: #303030;
    --text-primary: white;
    --accent-primary: #FFD700;
    --accent-secondary: #FF00FF;
    --accent-tertiary: #5600b3;
    --success: #7EFF00;
}
:root {
    --picking-side: var(--accent-secondary);
    // --nav-bg: var(--bg-primary);
    --nav-bg: #101010DD;
    --card-background: var(--bg-primary-light-3);
    --elevation-shadow: var(--bg-primary-dark);
    --champion-select-search-bg: var(--bg-primary-light-3);
    --champion-pick-bg: var(--bg-primary-light-3);
    --main-bg: var(--bg-primary);
}