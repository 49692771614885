main.draft--wrapper {
    box-sizing: border-box;
    height: calc(100vh - 60px);
    padding-top: 20px;
    overflow: hidden;
    display: grid;

    @media screen and (max-width: 800px) {
        height: calc(200vh);
    }

    &.wait-ready-check {
        place-content: center;

        &>h1 {
            font-size: 3em;
            font-family: 'Rubik';
            text-align: center;
            padding: 0 40px 10vh;
        }

        @media screen and (max-width: 800px) {
            place-content: start center;

            &>h1 {
                font-size: 3em;
                font-family: 'Rubik';
                padding: 30vh 40px 0;
            }
        }
    }

    &>.pickban-select--wrapper {
        display: grid;
        grid-template-columns: 24vw auto 24vw;
        grid-template-areas: "blue select red";
        height: 98%;
        min-height: 0;

        @media screen and (max-width: 1800px) {
            column-gap: 80px;
            grid-template-columns: 1fr auto 1fr;
        }
        @media screen and (max-width: 1600px) {
            column-gap: 20px;
            grid-template-columns: 1fr auto 1fr;
        }

        @media screen and (max-width: 800px) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: calc(100vh - 60px) 100vh;
            row-gap: 20px;
            column-gap: 5px;
            grid-template-areas: "blue red"
                "select select";
        }
    }
}
