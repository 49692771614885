* {
    font-family: 'Poppins';
}
@font-face {
    font-family: 'Hyperwave';
    src: url('../../assets/fonts/Hyperwave-Two.ttf');
}
@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('../../assets/fonts/Poppins-Regular.ttf');
}