.hr 
    height: 3px
    width: 80%
    background-color: rgb(68, 68, 68)
    border-radius: 2px
    margin: 10px auto 7px

.actions 
    display: grid
    grid-template-columns: 1fr 40px 40px
    height: calc(1.2em + 24px)

    &>*
        display: grid
        place-content: center
        height: 100%
        box-shadow: none
        border-radius: 0px
        transition: background-color 350ms
        background-color: var(--bg-primary)

        &:hover
            background-color: var(--bg-primary-light-2)
            box-shadow: none
            & svg 
                fill: white
                
    & #red-link
        color: inherit

    & #blue-link
        color: inherit

    

    &>button, &>a
        cursor: pointer
        padding: 0

    & input[type=text]
        font-size: 1.2em
        padding: 10px 10px
        color: #bbb
        box-shadow: none
        background-color: var(--bg-primary-light-2)

    & svg 
        fill: var(--bg-primary-light)
    


.container
    display: grid
    row-gap: 10px

    &>h1
        box-sizing: border-box
        padding: 10px 20px
        text-align: center
        font: 1.7em 'Rubik'
        color: var(--accent-tertiary)
        filter: brightness(1.5)

    &>span
        color: grey
        text-align: center
        padding-bottom: 20px

    & label
        padding-left: 5px
        font: 1.3em 'Rubik'

        &.red
            color: #ff0000

        &.blue
            color: #0051ff

        &>span
            font-size: 0.7em
            color: grey

button.copy-all 
    padding: 0
    width: 120px
    margin: 10px auto 5px
    height: 40px
    font-size: 0.8em