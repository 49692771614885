.container
    display: grid
    height: calc(100vh - 120px)
    padding-bottom: 120px
    place-content: center
    grid-auto-flow: column
    column-gap: 10px

    @media screen and (max-width: 800px)
        grid-auto-flow: row
        height: max-content
        padding: 20px
        row-gap: 10px

    &>.link-holder
        box-sizing: border-box
        display: grid
        height: max-content
        max-width: 380px
        width: 30vw
        min-width: 200px
        row-gap: 5px
        padding: 20px 40px 20px

        @media screen and (max-width: 800px)
            background-color: var(--bg-primary-1)
            width: 100vw
            max-width: none

    & .options 
        display: grid
        grid-template-columns: 1fr auto
        color: grey
        text-transform: uppercase
        & svg 
            fill: grey

        &::before
            content: ""
            position: relative
            grid-column: 1/3
            top: -6px
            left: 0px
            height: 3px
            width: 80%
            background-color: rgb(68, 68, 68)
            border-radius: 2px
            margin: 15px auto

    &>form.content
        box-sizing: border-box
        display: grid
        width: 380px
        padding: 15px 15px 30px

        @media screen and (max-width: 800px)
            background-color: var(--bg-primary-1)
            width: 100vw

        &>h1
            font-family: 'Hyperwave'
            color: var(--accent-primary)
            font-size: 7em
            text-align: center
            margin-bottom: 10px

        &>span
            margin-top: -30px
            text-align: center
            color: grey

        &>button
            margin: 15px 20px 0px
            cursor: pointer
            &:disabled
                cursor: not-allowed
                color: #09ff00 !important
                box-shadow: none

        &>div
            box-sizing: border-box
            display: grid
            row-gap: 8px
            padding: 5px 25px
            max-height: calc(100px + 35vh)
            overflow-y: scroll

            @media screen and (max-width: 800px)
                max-height: none

            input[type="text"].controlled-text-input
                font-size: 1.3em

            &>div.team-selection
                display: grid
                grid-template-columns: 1fr auto
                grid-template-rows: auto auto
                row-gap: 10px
                padding-bottom: 20px

                &::after
                    content: ""
                    position: relative
                    grid-column: 1/3
                    margin: 0 auto
                    top: 10px
                    left: 0px
                    height: 3px
                    width: 80%
                    background-color: rgb(68, 68, 68)
                    border-radius: 2px
                    margin: 0 auto

                &>label
                    padding-top: 3px
                    font-size: 1.2em

            &>.time-limits
                display: grid
                padding: 15px 0px 0px 0px
                grid-template-columns: 1fr 80px
                row-gap: 15px
                column-gap: 10px
                font-size: 1.3em

                .timer-toggle
                    margin-left: auto
                    padding-right: 5px

                &>label.seconds
                    padding-top: 8px
                    color: #aaa

                &>input[type=text]
                    text-align: center
                    font-size: 1em

