button.champion-icon--wrapper {
    display: grid;
    padding: 15px 5px 10px;
    margin: 5px auto;
    width: 110px;
    background-color: transparent;
    box-shadow: none;
    transition: filter 350ms, background-color 350ms;
    @media screen and (max-width: 800px) {
        padding: 10px 0px 5px;
        width: 100px;
    }
    & > img {
        max-width: 80px;
        margin: 0 auto;
        background-color: black;
    }
    & > p {
        text-align: center;
        width: 100%;
        font-size: 0.9rem;
    }
    &:disabled {
        filter: grayscale(1);
        background-color: #1C1C1C;
        & > p {
            color: grey;
        }
    }
}