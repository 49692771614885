.container {
    border-radius: 4px;
    background-color: #252525;
    padding: 40px;
    font-size: 1.3em;
    display: grid;
    row-gap: 20px;

    &.error-state {
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        padding: 60px 80px;
    }

    &>h2 {
        font-family: "Arvo";
        font-size: 1.3em;
        color: var(--accent-primary);
        text-align: center;
    }

    &>p.date {
        text-align: center;

        &>span {
            color: grey;
        }
    }

    &>a.view {
        background-color: #202020;
        padding: 20px;
        text-decoration: none;
        border-radius: 4px;
        font-family: "Arvo";
        text-transform: uppercase;
        color: var(--accent-secondary);
        text-align: center;
        filter: brightness(1);
        transition: filter 250ms;

        &:hover {
            filter: brightness(1.5);
        }
    }
}